import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const OtherProjectsItem = styled.div`
	width: 100%;
	height: 100%;
	text-decoration: none;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
`

const OtherProjectsItemBackgroundContainer = styled.div`
	grid-column: 1;
	grid-row: 1;
	/* width: 100%; */
	overflow: hidden;

	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	z-index: 0;

	& > video {
		grid-column: 1;
		grid-row: 1;
		z-index: 2;
	}

	& > .gatsby-image-wrapper {
		grid-column: 1;
		grid-row: 1;
		z-index: 2;

		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	border-radius: 10px;
`

const OtherProjectsItemVideoBackground = styled.video`
	width: fill-available;
	height: fill-available;
	overflow: hidden;
	object-fit: cover;
	object-position: 50% 0%;
`

const OtherProjectsInformation = styled.div`
	padding: 5%;
	opacity: 0;
	background: hsla(0, 0%, 0%, 0.85);
	transition: all 300ms ease;
	border-radius: 10px;
	grid-column: 1;
	grid-row: 1;
	z-index: 1;

	&:hover,
	&:focus {
		opacity: 1;
	}

	& > h3,
	& > h4,
	& > p,
	& > ul {
		/* color: var(--primary-text-colour); */
		color: white;
		filter: opacity(1);
		margin-bottom: 0.5rem;
		line-height: 1.2;
		font-weight: normal;
	}

	& > h3 {
		font-weight: 500;
		font-size: 16pt;
		letter-spacing: 0.5px;
	}

	& > h4 {
		font-weight: 300;
		font-size: 12pt;
		opacity: 0.7;
	}

	& > p {
		font-size: 10pt;
		font-weight: 300;
		line-height: 1.4;
	}
`

const WorkDisciplineList = styled.ul`
	grid-row: 4;
	font-size: 9pt;
	list-style-type: none;
	& > li {
		display: inline-block;
		& > span {
			margin: 0 6px;
		}
	}
	@media (min-width: 768px) {
		font-size: 14pt;
	}
`

export default class OtherProject extends Component {
	render() {
		const { data } = this.props
		return (
			<Link
				to={`/${data.link}/`}
				name="other-project"
				style={{ textDecoration: 'none' }}
			>
				<OtherProjectsItem>
					<OtherProjectsItemBackgroundContainer>
						{data.backgroundMediaSrc !== null ? (
							<Img
								fluid={data.backgroundMediaSrc}
								alt={'Decorative cover image for the project.'}
							/>
						) : null}
						{data.backgroundMediaVideo !== null ? (
							<OtherProjectsItemVideoBackground autoPlay loop muted>
								<source src={data.backgroundMediaVideo} type="video/mp4" />
								Sorry, your browser doesn’t support embedded videos.
							</OtherProjectsItemVideoBackground>
						) : null}
					</OtherProjectsItemBackgroundContainer>
					<OtherProjectsInformation>
						<h3>{data.title}</h3>
						<h4>{data.subtitle}</h4>
						<p>{data.description}</p>
						<WorkDisciplineList>
							{data.disciplines.map((discipline, index) => (
								<li key={index}>
									{discipline}
									{data.disciplines.length === index + 1 ? null : (
										<span>∙</span>
									)}
								</li>
							))}
						</WorkDisciplineList>
					</OtherProjectsInformation>
				</OtherProjectsItem>
			</Link>
		)
	}
}

OtherProject.propTypes = {
	data: PropTypes.shape({
		active: PropTypes.bool,
		link: PropTypes.string,
		title: PropTypes.string,
		subtitle: PropTypes.string,
		description: PropTypes.string,
		disciplines: PropTypes.array,
		backgroundMediaSrc: PropTypes.any,
		backgroundMediaVideo: PropTypes.string
	})
}

OtherProject.defaultProps = {
	data: PropTypes.shape({
		active: false,
		link: null,
		title: 'Placeholder title',
		subtitle: 'Placeholder subtitle',
		description: 'Placeholder Description',
		disciplines: ['Placeholder Alt Text1', 'Placeholder Alt Text2'],
		backgroundMediaSrc: null,
		backgroundMediaVideo: null
	})
}
