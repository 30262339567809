import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled, { keyframes } from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import CaseStudy from '../components/case-study'
import OtherProject from '../components/other-project'

import { caseStudyData } from '../data/index-case-study-data'
import { otherProjectsData } from '../data/index-projects-data'

import gattacaBackgroundVideo from '../motion/gattaca-background.mp4'
import ysdnBackgroundVideo from '../motion/ysdnBackgroundImage.mp4'
// import googleBackgroundImage from '../motion/i-heart-y-u.mp4'

otherProjectsData[3].backgroundMediaVideo = ysdnBackgroundVideo
otherProjectsData[4].backgroundMediaVideo = gattacaBackgroundVideo
// otherProjectsData[5].backgroundMediaVideo = googleBackgroundImage

// -------------------

const IntroductionContainer = styled.section`
	min-height: 100vh;
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-gap: 24px;
	grid-template-rows: minmax(100px, auto);

	& > * {
		grid-column: 1 / span 12;
	}

	@media (min-width: 576px) {
	}

	@media (min-width: 768px) {
	}

	@media (min-width: 992px) {
	}

	@media (min-width: 1200px) {
	}
`

const IntroductionGroup = styled.header`
	align-self: flex-end;
	grid-column: 1 / span 12;

	@media (min-width: 576px) {
		grid-column: 1 / span 11;
	}

	@media (min-width: 768px) {
		grid-column: 1 / span 10;
	}

	@media (min-width: 992px) {
		grid-column: 1 / span 8;
	}

	@media (min-width: 1200px) {
		grid-column: 1 / span 7;
	}
`

const FadeUpH1 = keyframes`
	from {
		transform: translateY(20px);
		opacity: 0;
	}

	to {
		transform: translateY(0);
		opacity: 1;
	}
`

const HeadingOne = styled.h1`
	font-size: 32px;
	line-height: 1.333;
	animation: ${FadeUpH1} 1000ms ease-out 500ms both;

	@media (min-width: 576px) {
	}

	@media (min-width: 768px) {
		line-height: 1.2;
		font-size: 48px;
	}

	@media (min-width: 992px) {
	}

	@media (min-width: 1200px) {
	}
`

const FadeUpH2 = keyframes`
	from {
		transform: translateY(20px);
		opacity: 0;
	}

	to {
		transform: translateY(0);
		opacity: 1;
	}
`

const HeadingTwo = styled.h2`
	/* font-size: 12px; */
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 1.5px;
	animation: ${FadeUpH2} 1000ms ease-out;
	margin-bottom: 12px;

	@media (min-width: 576px) {
	}

	@media (min-width: 768px) {
		/* margin-bottom: 24px; */
		font-size: 18px;
	}

	@media (min-width: 992px) {
	}

	@media (min-width: 1200px) {
	}
`

const WaveSideToSide = keyframes`
	0% {
		transform: rotate(0);
	}

	25% {
		transform: rotate(15deg);
	}

	70% {
		transform: rotate(-15deg);
	}

	100% {
		transform: rotate(0);
	}
`

const WaveEmoji = styled.div`
	animation: ${WaveSideToSide} 750ms ease 750ms;
	display: inline-block;
	margin-right: 10px;
	transform-origin: bottom right;
	transition: all 300ms ease-in-out;

	&:hover {
		transform: rotateZ(15deg);
	}

	@media (min-width: 576px) {
	}

	@media (min-width: 768px) {
	}

	@media (min-width: 992px) {
	}

	@media (min-width: 1200px) {
	}
`

const FadeInOnLoad = keyframes`
	from {
		opacity: 0;
		visibility: hidden;
	}

	to {
		opacity: 1;
		visibility: visible;
	}
`

const ScrollDownAnimation = keyframes`
	0% {
		transform: translateY(0);
		opacity: 0;
	}

	20% {
		opacity: 1;
	}

	80% {
		opacity: 1;
	}

	100% {
		transform: translateY(20px);
		opacity: 0;
	}
`

const ScrollMouseSVG = styled.svg`
	grid-column: 6 / span 2;
	width: 40px;
	height: 60px;
	align-self: center;
	justify-self: center;
	opacity: 0;
	visibility: hidden;
	animation: ${FadeInOnLoad} 1s ease-out both;
	transition: all 500ms ease-in-out;

	& > * {
		backdrop-filter: invert(1);
	}

	& > path {
		fill: none;
		stroke: var(--primary-text-colour);
		stroke-width: 3;
		stroke-miterlimit: 10;
	}

	& > g > circle {
		fill: var(--primary-text-colour);
		animation: ${ScrollDownAnimation} 1.75s ease infinite;
	}

	@media (prefers-color-scheme: light) {
		& > path {
			stroke: var(--primary-text-colour);
		}
		& > g > circle {
			fill: var(--primary-text-colour);
		}
	}

	@media (min-width: 576px) {
	}

	@media (min-width: 768px) {
	}

	@media (min-width: 992px) {
	}

	@media (min-width: 1200px) {
	}
`

const CaseStudiesContainer = styled.section`
	min-height: 100vh;
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-template-rows: 20px repeat(4, 50vh);

	grid-gap: 24px;
	/* margin-bottom: 24px; */

	& > * {
		grid-column: 1 / span 12;
	}

	@media (min-width: 576px) {
	}

	@media (min-width: 768px) {
		grid-gap: 24px;
	}

	@media (min-width: 992px) {
	}

	@media (min-width: 1200px) {
		/* grid-template-rows: 20px 50vh 50vh 50vh; */
	}
`

const OtherProjectsContainer = styled.section`
	/* min-height: 100vh; */
	display: grid;
	grid-gap: 12px;
	grid-template-columns: repeat(12, 1fr);
	grid-template-rows: 20px repeat(4, 80vw);

	& > * {
		grid-column: 1 / span 12;
	}

	@media (min-width: 576px) {
	}

	@media (min-width: 768px) {
		grid-template-rows: 20px repeat(2, minmax(0, 40vw));
		grid-gap: 24px;

		& > *:nth-child(even) {
			grid-column: 1 / span 6;
		}
		& > *:nth-child(odd) {
			grid-column: 7 / span 6;
		}
		& > *:first-child {
			grid-row: 1;
			grid-column: 1 / span 6;
		}
		& > *:nth-child(2),
		& > *:nth-child(3) {
			grid-row: 2;
		}

		& > *:nth-child(4),
		& > *:nth-child(5) {
			grid-row: 3;
		}

		& > *:nth-child(6),
		& > *:nth-child(7) {
			grid-row: 4;
		}

		& > *:nth-child(8),
		& > *:nth-child(9) {
			grid-row: 5;
		}
	}

	@media (min-width: 992px) {
	}

	@media (min-width: 1200px) {
		grid-template-rows: 20px repeat(2, 384px);

		& > *:nth-child(3n-1) {
			grid-column: 1 / span 4;
		}
		& > *:nth-child(3n) {
			grid-column: 5 / span 4;
		}
		& > *:nth-child(3n + 1) {
			grid-column: 9 / span 4;
		}
		& > *:first-child {
			grid-column: 1 / span 4;
			grid-row: 1;
		}
		& > *:nth-child(2),
		& > *:nth-child(3),
		& > *:nth-child(4) {
			grid-row: 2;
		}

		& > *:nth-child(5),
		& > *:nth-child(6),
		& > *:nth-child(7) {
			grid-row: 3;
		}

		& > *:nth-child(8),
		& > *:nth-child(9),
		& > *:nth-child(10) {
			grid-row: 4;
		}
	}
`

export default class IndexPage extends Component {
	constructor(props) {
		super(props)
		this.state = { hasScrolled: false }
		this.handleScroll = this.handleScroll.bind(this)
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll)
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll)
	}

	handleScroll() {
		this.setState({
			hasScrolled: true
		})
	}

	render() {
		caseStudyData[0].gatsbyImg = this.props.data.castleImage.childImageSharp.fluid
		caseStudyData[1].gatsbyImg = this.props.data.fontBookX.childImageSharp.fluid
		caseStudyData[2].gatsbyImg = this.props.data.WanderHomeImage.childImageSharp.fluid
		caseStudyData[3].gatsbyImg = this.props.data.AppleWatchAppsImage.childImageSharp.fluid

		otherProjectsData[0].backgroundMediaSrc = this.props.data.atfl2019ProjectImage.childImageSharp.fluid
		otherProjectsData[1].backgroundMediaSrc = this.props.data.ttbbProjectImage.childImageSharp.fluid
		otherProjectsData[2].backgroundMediaSrc = this.props.data.ysdn2019Image.childImageSharp.fluid
		otherProjectsData[3].backgroundMediaSrc = this.props.data.ysdnTeaserProjectImage.childImageSharp.fluid
		otherProjectsData[4].backgroundMediaSrc = this.props.data.gattacaProjectImage.childImageSharp.fluid
		otherProjectsData[5].backgroundMediaSrc = this.props.data.housingProjectImage.childImageSharp.fluid

		return (
			<Layout>
				<SEO
					title="Work"
					keywords={[
						'Aaron Vince',
						'Portfolio',
						'Design',
						'Graphic Design',
						'UI/UX',
						'Front-end Development',
						'Gatsby',
						'Application',
						'React'
					]}
				/>
				<IntroductionContainer>
					<IntroductionGroup>
						<HeadingTwo>
							Hey!{' '}
							<WaveEmoji>
								<span role="img" aria-label="Waving Hand! ">
									👋
								</span>
							</WaveEmoji>
							I’m Aaron
						</HeadingTwo>
						<HeadingOne>
							I’m a Designer Focused on UX/UI Design, Front-End Web Design, &
							Motion Design.
						</HeadingOne>
					</IntroductionGroup>
					<ScrollMouseSVG
						style={
							this.state.hasScrolled === true ? { transform: 'scale(0)' } : {}
						}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 40 60"
					>
						<path d="M20,58L20,58c-9.9,0-18-8.1-18-18V20c0-9.9,8.1-18,18-18h0c9.9,0,18,8.1,18,18v20C38,49.9,29.9,58,20,58z" />
						<g>
							<circle cx="20" cy="20" r="4" />
						</g>
					</ScrollMouseSVG>
				</IntroductionContainer>
				<CaseStudiesContainer>
					<HeadingTwo>Case Studies</HeadingTwo>
					{caseStudyData.map((item, index) =>
						item.active === true ? <CaseStudy key={index} data={item} /> : null
					)}
				</CaseStudiesContainer>

				<OtherProjectsContainer>
					<HeadingTwo>Other Projects</HeadingTwo>
					{otherProjectsData.map((item, index) =>
						item.active === true ? (
							<OtherProject key={index} data={item} />
						) : null
					)}
				</OtherProjectsContainer>
			</Layout>
		)
	}
}

IndexPage.propTypes = {
	data: PropTypes.object
}

export const pageQuery = graphql`
	query {
		castleImage: file(relativePath: { regex: "/castle--hero-image.png/" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		fontBookX: file(relativePath: { regex: "/Library — Font Details View/" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		WanderHomeImage: file(relativePath: { regex: "/homeward--hero-image/" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		AppleWatchAppsImage: file(
			relativePath: {
				regex: "/apple-watch-apps--monthly-workouts-redesign.png/"
			}
		) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		atfl2019ProjectImage: file(
			relativePath: { regex: "/atfl2019--hero-image.png/" }
		) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		ysdn2019Image: file(relativePath: { regex: "/ysdn2019-header.png/" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		ysdnTeaserProjectImage: file(
			relativePath: { regex: "/ysdnBackgroundImage.png/" }
		) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		gattacaProjectImage: file(
			relativePath: { regex: "/gattaca-background.png/" }
		) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		housingProjectImage: file(
			relativePath: { regex: "/housing-homelessness-and-wait-lists.png/" }
		) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		ttbbProjectImage: file(
			relativePath: { regex: "/trying-to-be-better-episode-image.png/" }
		) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`
