import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const WorkItem = styled.div`
	display: grid;
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: 12px;
	margin-bottom: 24px;
	/* border-top: 0.5px solid #333;
	border-bottom: 0.5px solid #111; */
	grid-template-columns: 100%;
	grid-template-rows: 100%;

	& > * {
		grid-row: 1;
		grid-column: 1;
	}
`

const WorkItemContainer = styled.div`
	width: 90%;
	height: fill-available;
	padding: 5%;
	transition: all 500ms ease;
	transform: scale(1);

	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-gap: 12px;
	grid-template-rows: minmax(12px, auto);

	/* color: var(--primary-text-colour); */
	color: white;
	opacity: 0;
	background: hsla(0, 0%, 0%, 0.85);
	transition: all 300ms ease;

	&:hover {
		opacity: 1;
	}

	& > * {
		grid-column: 1 / span 12;
	}

	& > :not(div) {
		filter: brightness(100%);
	}

	@media (min-width: 768px) {
		opacity: 0;
		grid-gap: 24px;
	}
`

const WorkHeadingThree = styled.h3`
	grid-row: 1;
	font-size: 16pt;
	opacity: 1;
	@media (min-width: 768px) {
		font-size: 24pt;
	}
`

const WorkHeadingFour = styled.h4`
	grid-row: 2;
	font-size: 12pt;
	opacity: 0.85;
	@media (min-width: 768px) {
		font-size: 18pt;
	}
`

const WorkDescription = styled.p`
	grid-row: 3;
	width: 100%;
	font-size: 10pt;
	line-height: 1.4;
	@media (min-width: 768px) {
		width: 50%;
		font-size: 16pt;
	}
`

const WorkDisciplineList = styled.ul`
	grid-row: 4;
	font-size: 9pt;
	list-style-type: none;

	& > li {
		display: inline-block;

		& > span {
			margin: 0 6px;
		}
	}
	@media (min-width: 768px) {
		font-size: 14pt;
	}
`

const WorkBackgroundImageContainer = styled.div`
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	& * {
		/* width: fit-content; */
		/* height: fit-content; */
		/* object-fit: cover; */
		/* object-position: 50%; */

		width: 100%;
		height: 100%;
	}
`

export default class CaseStudy extends Component {
	render() {
		const { data } = this.props
		return (
			<Link
				to={`/${data.link}/`}
				name="case-study"
				style={{ textDecoration: 'none' }}
			>
				<WorkItem>
					<WorkBackgroundImageContainer>
						{data.gatsbyImg !== null ? (
							<Img fluid={data.gatsbyImg} />
						) : (
							<img src={data.image} alt="" />
						)}
					</WorkBackgroundImageContainer>

					<WorkItemContainer>
						<WorkHeadingThree>{data.title}</WorkHeadingThree>
						<WorkHeadingFour>{data.subtitle}</WorkHeadingFour>
						<WorkDescription>{data.description}</WorkDescription>
						<WorkDisciplineList>
							{data.disciplines.map((discipline, index) => (
								<li key={index}>
									{discipline}
									{data.disciplines.length === index + 1 ? null : (
										<span>∙</span>
									)}
								</li>
							))}
						</WorkDisciplineList>
					</WorkItemContainer>
				</WorkItem>
			</Link>
		)
	}
}

CaseStudy.propTypes = {
	data: PropTypes.object
}

CaseStudy.defaultProps = {
	data: null
}
